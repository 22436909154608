const blobCursor = function () {
	const cursor = document.querySelector("#cursor-blob");
	const link = document.querySelectorAll(
		".btn, a, .link-hover-image, .carousel-control-prev, .carousel-control-next, .carousel-indicators button"
	);

	const setCursorPos = (e) => {
		let posX = e.pageX;
		let posY = e.pageY;

		cursor.style.top = posY - cursor.offsetHeight / 2 + "px";
		cursor.style.left = posX - cursor.offsetWidth / 2 + "px";
	};

	document.addEventListener("mousemove", setCursorPos);

	const setCursorHover = function () {
		cursor.style.transform = "scale(2)";
	};
	const removeCursorHover = function () {
		cursor.style.transform = "";
	};

	link.forEach((el) => {
		el.addEventListener("mouseover", setCursorHover);
		el.addEventListener("mouseleave", removeCursorHover);
	});
};

blobCursor();
