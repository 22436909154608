const header = document.querySelector("#header");

function headerScroll() {
	if (window.scrollY > 90) {
		header.classList.add("scrolled");
	} else {
		header.classList.remove("scrolled");
	}
}

function headerFixedTop() {
	if (header.classList.contains("fixed-top")) {
		const sectionFirst = document.querySelector("section:first-of-type");
		sectionFirst.style.paddingTop = `${header.offsetHeight}px`;
	}
}

function sliderPartnerInit() {
	new Swiper(".swiper-partner", {
		loop: false,

		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},

		slidesPerView: 1,
		spaceBetween: 10,

		breakpoints: {
			480: {
				slidesPerView: 2,
				spaceBetween: 30,
			},

			768: {
				slidesPerView: 5,
				spaceBetween: 40,

				navigation: {
					enabled: false,
				},
			},
		},
	});
}

function sliderBg() {
	new Swiper(".swiper.background-swiper", {
		loop: true,
		spaceBetween: 0,
		effect: "fade",
		allowTouchMove: false,
		speed: 1000,

		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
	});
}

function positionElementInit() {
	const cursorHover = document.querySelectorAll(".link-hover-image");

	cursorHover.forEach((el) => {
		let newItem = document.createElement("img");
		let itemData = el.getAttribute("data-hover-image");
		newItem.setAttribute("src", itemData);
		newItem.setAttribute("class", "hover-image");

		el.appendChild(newItem);

		const cursorImage = el.querySelector(".hover-image");

		const positionElement = (e) => {
			const rect = e.target.getBoundingClientRect();
			const mouseY = e.clientY - rect.top;
			const mouseX = e.clientX - rect.left;

			cursorImage.style.left = `${mouseX}px`;
			cursorImage.style.top = `${mouseY}px`;
		};

		el.addEventListener("mousemove", positionElement);
	});
}

function parallaxEffect() {
	let parallaxItem = document.querySelectorAll(".parallax-effect");

	new simpleParallax(parallaxItem, {
		delay: 0.6,
		transition: "cubic-bezier(0,0,0,1)",
		scale: 1.1,
	});
}

function parallaxEffectBg() {
	let parallaxBgContainer = document.querySelector(".parallax-bg-container");
	let parallaxBg = document.querySelectorAll(".parallax-bg");

	new simpleParallax(parallaxBg, {
		scale: 1.15,
		customContainer: parallaxBgContainer,
	});
}

function parallaxEffectSection() {
	let parallaxSection = document.querySelector(".parallax-section");

	new simpleParallax(parallaxSection, {
		orientation: "down",
		scale: 1.5,
		overflow: true,
		delay: 0.6,
		transition: "cubic-bezier(0,0,0,1)",
		maxTransition: 49,
	});
}

function openCloseCollapseMap() {
	const collapseMap = document.getElementById("collapseMap");
	const collapseMapLabel = document.querySelector("button.collapse-map-label");

	collapseMap.addEventListener("show.bs.collapse", (event) => {
		collapseMapLabel.innerHTML = "Chiudi la mappa";
	});

	collapseMap.addEventListener("hide.bs.collapse", (event) => {
		collapseMapLabel.innerHTML = "Apri la mappa";
	});
}

function openCollapseBtn() {
	const btnTrovaci = document.querySelector("[href='#footer']");
	console.log(btnTrovaci);

	btnTrovaci.addEventListener("click", () => {
		const bsCollapse = new bootstrap.Collapse("#collapseMap", {
			toggle: true,
		});

		return bsCollapse;
	});
}

const gMapStyles = [
	{
		elementType: "geometry",
		stylers: [
			{
				color: "#f5f5f5",
			},
		],
	},
	{
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#616161",
			},
		],
	},
	{
		elementType: "labels.text.stroke",
		stylers: [
			{
				color: "#f5f5f5",
			},
		],
	},
	{
		featureType: "administrative.land_parcel",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#bdbdbd",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "geometry",
		stylers: [
			{
				color: "#eeeeee",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#757575",
			},
		],
	},
	{
		featureType: "poi.park",
		elementType: "geometry",
		stylers: [
			{
				color: "#e5e5e5",
			},
		],
	},
	{
		featureType: "poi.park",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#9e9e9e",
			},
		],
	},
	{
		featureType: "road",
		elementType: "geometry",
		stylers: [
			{
				color: "#ffffff",
			},
		],
	},
	{
		featureType: "road.arterial",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#757575",
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "geometry",
		stylers: [
			{
				color: "#dadada",
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#616161",
			},
		],
	},
	{
		featureType: "road.local",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#9e9e9e",
			},
		],
	},
	{
		featureType: "transit.line",
		elementType: "geometry",
		stylers: [
			{
				color: "#e5e5e5",
			},
		],
	},
	{
		featureType: "transit.station",
		elementType: "geometry",
		stylers: [
			{
				color: "#eeeeee",
			},
		],
	},
	{
		featureType: "water",
		elementType: "geometry",
		stylers: [
			{
				color: "#c9c9c9",
			},
		],
	},
	{
		featureType: "water",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#9e9e9e",
			},
		],
	},
];

// Initialize and add the map
function initMap() {
	// The location of Uluru
	const position = { lat: 45.67871133867292, lng: 9.236307995425244 };

	const svgMarker = {
		path: "M11.2154 32.6608C1.75586 18.9473 0 17.5399 0 12.5C0 5.59642 5.59642 0 12.5 0C19.4036 0 25 5.59642 25 12.5C25 17.5399 23.2441 18.9473 13.7846 32.6608C13.1639 33.5576 11.8361 33.5575 11.2154 32.6608ZM12.5 17.7083C15.3765 17.7083 17.7083 15.3765 17.7083 12.5C17.7083 9.6235 15.3765 7.29167 12.5 7.29167C9.6235 7.29167 7.29167 9.6235 7.29167 12.5C7.29167 15.3765 9.6235 17.7083 12.5 17.7083Z",
		fillColor: "#797979",
		fillOpacity: 1,
		strokeWeight: 0,
		rotation: 0,
		scale: 1.5,
		// anchor: new google.maps.Point(10, 50),
		labelOrigin: new google.maps.Point(130, 20),
	};

	// The map, centered at Uluru
	const map = new google.maps.Map(document.getElementById("map"), {
		zoom: 16,
		center: position,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: false,
		styles: gMapStyles,
	});
	// The marker, positioned at Uluru
	const marker = new google.maps.Marker({
		position: position,
		map: map,
		icon: svgMarker,
		label: {
			text: "Corso della Liberta, 33, 20841 Carate Brianza MB",
			color: "#797979",
			fontWeight: "bold",
		},
	});
}

window.addEventListener("DOMContentLoaded", (event) => {
	AOS.init({
		duration: 900,
	});
	sliderPartnerInit();
	sliderBg();
	headerScroll();
	headerFixedTop();
	parallaxEffect();
	// parallaxEffectBg();
	parallaxEffectSection();
	positionElementInit();
	openCloseCollapseMap();
	openCollapseBtn();
	initMap();
});

window.addEventListener("resize", (event) => {
	headerFixedTop();
});

window.addEventListener("scroll", (event) => {
	headerScroll();
});
